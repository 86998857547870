import { css } from '@emotion/core'
import { smallOnly, mediumUp, largeUp } from 'Utils'
import theme from 'Theme'
import config from 'Config'

const { colors, spacing, typography, layout, mixins } = theme

const { sectionHeading, mainContainerTopPadding } = mixins

export default props => css`
  ${config.articlesEnabled && mainContainerTopPadding(theme)}

  min-height: 100vh;

  /* on mobile hide intros and increase spacing */
  .content-section {
    .content-list__sub-title {
      @media ${smallOnly} {
        display: none;
      }
    }
    .content-list__title {
      @media ${smallOnly} {
        margin: 0 auto ${spacing.vertical.small} auto;
      }
    }
    .content-list__firstArticle {
      margin-bottom: 20px;
    }
  }

  .categories-section {
    background-color: white;
  }

  .content-list__articles,
  .content-list__trends,
  .home-footer__grid {
    width: 96%;
    max-width: ${layout.maxWidth.mainContainer}px;
    margin: 0 auto ${spacing.vertical.medium} auto;
  }

  .category-grid {
    width: 96%;
    max-width: ${layout.maxWidth.mainContainer}px;
    margin: 0 auto;
  }

  .content-list__articles {
    .content-list {
      padding-top: 0;
    }

    .featured {
      margin-bottom: ${spacing.vertical.small};
    }
    .content-list__grid {
      margin-bottom: ${spacing.vertical.small};
    }
  }

  /* hero section */
  .hero-section {
    width: 80%;
    max-width: 520px;
    text-align: center;
    padding: ${spacing.vertical.medium};
    margin: 0 auto;
  }

  .hero-section__heading {
    ${sectionHeading(theme)}
    font-size: ${typography.sizes.large};
    line-height: 1;
    margin-bottom: ${spacing.vertical.xsmall};
    @media ${mediumUp} {
      font-size: ${typography.sizes.large};
    }
  }

  .hero-section__intro {
    font-weight: normal;
  }

  .category-heading {
    ${sectionHeading(theme)}
    display: table;
    padding-top: ${spacing.vertical.medium};
    margin: 0 auto ${spacing.vertical.medium} auto;
    color: #1d2f3d;
  }

  .category-panel__title,
  .home-footer__heading,
  .hero-section__heading {
    color: #1d2f3d;
  }

  .category-panel__frame {
    border: none !important;
  }

  .content-list-item {
    margin-bottom: ${theme.spacing.vertical.small};
  }

  .content-section {
    .content-list {
      padding-bottom: 0;
    }

    .content-list__grid {
      margin-bottom: ${theme.spacing.vertical.small};
      margin-left: auto;
      margin-right: auto;
    }

    .content-list__sub-title {
      max-width: 400px;
      font-size: ${typography.baseFontSize};
    }
  }

  /* footer */
  .home-footer {
    background-color: white;
    padding: ${spacing.vertical.medium} 0;
  }

  .home-footer__heading {
    ${sectionHeading(theme)}
    display: table;
    margin: 0 auto ${spacing.vertical.medium} auto;
    @media ${mediumUp} {
      font-size: ${typography.sizes.larger};
    }
  }

  .home-footer__grid {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .home-footer__store {
      padding: ${spacing.vertical.small};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;

      img {
        width: 100%;
        max-width: 200px;
        max-height: 100px;
        object-fit: contain;
      }

      @media ${largeUp} {
        padding: 0 ${spacing.vertical.small};
        width: 25%;
        max-width: 200px;
      }
    }
  }
`

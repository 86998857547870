import React, { Component, Fragment, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_CONTENT } from 'Queries'
import config from 'Config'

import { Banner, Brand, FeaturedItems, ItemManager, ErrorView, TopProducts, LatestArticles } from 'Blocks'


const Home = props => {
  const [layers, setLayers] = useState({});
  const variables = {
    arguments: {
      uuid: 'frontpage',
      state: 'PUBLISHED'
    }
  }

  useEffect(() => {
    const ele = document.querySelector('.global-header');
    if(ele) {
      ele.classList.remove('active');
    }
  }, []);

  const { loading, error, data } = useQuery(GET_CONTENT, { variables })

  useEffect(() => {
    if(data) {
      let obj = {};

      if(data.content.layout.banners.length) {
        data.content.layout.banners.map(b => {
          obj[b.position.toString()] = {type: 'banners', data: b.data};
        });
      }

      if(data.content.layout.brands.length) {
        data.content.layout.brands.map(b => {
          obj[b.position.toString()] = {type: 'brands', data: b.data};
        });
      }

      if(data.content.layout.featureitems.length) {
        data.content.layout.featureitems.map(b => {
          obj[b.position.toString()] = {type: 'featureitems', data: b.data};
        });
      }

      if(data.content.layout.itemmanager.length) {
        data.content.layout.itemmanager.map(b => {
          obj[b.position.toString()] = {type: 'itemmanager', data: b.data};
        });
      }

      if(data.content.layout.topproducts.length) {
        data.content.layout.topproducts.map(b => {
          obj[b.position.toString()] = {type: 'topproducts', data: b.data};
        });
      }
      setLayers(obj);
    }
  }, [data])

  const LoadLayers = (data) => {
    const loader = layers[data.com]
    console.log(loader.type)
    switch(loader.type) {
      case 'banners':
        return (
          <div key={`c-${data.com}`}>
            <Banner data={loader} screenType={props.screenType} />
          </div>
        );
      case 'brands':
        return (
          <div key={`c-${data.com}`}>
            <Brand data={loader} screenType={props.screenType} />
          </div>
        );
      case 'featureitems':
        return (
          <div key={`c-${data.com}`}>
            <FeaturedItems data={loader} />
          </div>
        );
      case 'itemmanager':
        return (
          <div key={`c-${data.com}`}>
            <ItemManager data={loader} />
          </div>
        );
      case 'topproducts':
        return (
          <div key={`c-${data.com}`}>
            <TopProducts data={loader} />
          </div>
        )
      default:
        return null;
    }
  }

  if (error) return null;

  if(!data) return null;

  return (
    <React.Fragment>
      {Object.keys(layers).map((key, index) => {
        if(index === 1 && config.name === 'gr') {
          return (
            <div key='twillo-loader'>
              <LoadLayers com={key} key={`loader-${index}`} />
              <LatestArticles />
            </div>
          )
        }
        return <LoadLayers com={key} key={`loader-${index}`} />
      })}
    </React.Fragment>
  )
}

export default Home
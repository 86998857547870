import { css } from '@emotion/core'

export default () => css`
.product-journal-section {
  padding: 50px 0 0;
}

.section-container-1140 {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 20px;
}

.product-journal-section .product-journal-header-title {
  color: #191919;
  font-family: "Recife";
  font-size: 35px;
  text-align: center;
  line-height: 30px;
  letter-spacing: -1px;
}

.product-journal-section .product-journal-content-row {
  width: 100%;
  margin-top: 45px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}

.product-journal-section .product-journal-block {
  width: 48%;
  margin-bottom: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-journal-section .product-journal-image-block {
  width: 170px;
  height: 180px;
}

.product-journal-section .product-journal-image-block .global-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-journal-section .product-journal-content-block {
  width: calc(100% - 170px);
  padding-left: 30px;
  color: #191919;
}

.product-journal-section .product-journal-content-title {
  font-family: "Recife";
  font-size: 22px;
  line-height: 30px;
}

.product-journal-section .product-journal-content-description {
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.64px;
}

.product-journal-section .product-journal-link-row {
  margin-top: 24px;
}

.product-journal-section .content-link {
  color: #191919;
  font-size: 14px;
}

.product-journal-section .product-journal-loading-block {
  margin-top: 15px;
  text-align: center;
}

.progress-status {
    text-align: center;
}

@media (max-width: 767px) {
  .product-journal-section .product-journal-header-title {
    font-size: 30px;
    line-height: 38px;
  }

  .product-journal-section .product-journal-content-row {
    margin-top: 35px;
  }

  .product-journal-section .product-journal-block {
    width: 100%;
    margin-bottom: 30px;
  }

  .product-journal-section .product-journal-image-block {
    width: 110px;
    height: 120px;
  }

  .product-journal-section .product-journal-content-block {
    width: calc(100% - 110px);
    padding-left: 20px;
  }

  .product-journal-section .product-journal-content-title {
    font-size: 18px;
    line-height: 24px;
  }

  .product-journal-section .product-journal-content-description {
    font-size: 14px;
    line-height: 22px;
  }

  .product-journal-section .product-journal-link-row {
    margin-top: 14px;
  }
}

.product-journal-section .product-journal-loading-block {
  margin-top: 15px;
  text-align: center;
}

.primary-button.transparent-black {
  cursor: pointer;
    border-color: #000;
    color: #000;
    background: transparent; }
    .primary-button.transparent-black:hover {
      color: #0F085B;
      background: transparent; }
`

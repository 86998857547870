import { css } from '@emotion/core'

export default () => css`
.vertical-blocks-section {
  padding: 74px 0 84px;
}

.vertical-blocks-section .vertical-blocks-content-row {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
}
    
.vertical-blocks-section .vertical-blocks-left-content,
.vertical-blocks-section .vertical-blocks-right-content {
  width: 48%;
}

.vertical-blocks-section .vertical-left-image-block {
  width: 100%;
  height: 600px;
}

.vertical-blocks-section .vertical-left-image-block .global-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vertical-blocks-section .vertical-left-block-title {
  margin-top: 34px;
  font-family: "Recife";
  font-size: 35px;
}

.vertical-blocks-section .vertical-left-block-description {
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.64px;
}

.vertical-blocks-section .vertical-left-block-link-row,
.vertical-blocks-section .vertical-right-content-link-row {
  margin-top: 24px;
}

.vertical-blocks-section .vertical-left-block-link,
.vertical-blocks-section .vertical-right-content-link {
    font-size: 14px;
    text-decoration: underline;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.vertical-blocks-section .vertical-left-block-link:hover,
.vertical-blocks-section .vertical-right-content-link:hover {
  color: #121010;
  text-decoration: none;
}

.vertical-blocks-section .vertical-block-right-content-holder {
  margin-top: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.vertical-blocks-section .vertical-block-right-content-holder:first-child {
  margin-top: 0;
}

.vertical-blocks-section .vertical-right-image-block {
  width: 170px;
  height: 180px;
}


.vertical-blocks-section .vertical-right-image-block .global-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vertical-blocks-section .vertical-right-content-block {
  width: calc(100% - 170px);
  padding-left: 30px;
}

.vertical-blocks-section .vertical-right-content-title {
 font-family: "Recife";
 font-size: 22px;
}
    
.vertical-blocks-section .vertical-right-content-description {
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.64px;
}

@media (max-width: 767px) {
  .vertical-blocks-section {
    padding: 30px 0 0;
  }

  .vertical-blocks-section .vertical-blocks-left-content,
  .vertical-blocks-section .vertical-blocks-right-content {
    width: 100%;
  }

  .vertical-blocks-section .vertical-left-image-block {
    height: 350px;
  }

  .vertical-blocks-section .vertical-left-block-title {
    margin-top: 30px;
    font-size: 22px;
  }

  .vertical-blocks-section .vertical-left-block-description {
    margin-top: 8px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
  }

  .vertical-blocks-section .vertical-left-block-link-row {
    margin-top: 20px;
  }

  .vertical-blocks-section .vertical-left-block-link {
    font-size: 12px;
    letter-spacing: 1.3px;
  }

  .vertical-blocks-section .vertical-blocks-right-content {
    margin-top: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
  }

  .vertical-blocks-section .vertical-block-right-content-holder {
    width: 47%;
    margin-top: 0;
    margin-bottom: 30px;
  }

  .vertical-blocks-section .vertical-right-image-block {
    width: 100%;
  }

  .vertical-blocks-section .vertical-right-content-block {
    width: 100%;
    padding-top: 20px;
    padding-left: 0;
  }

  .vertical-blocks-section .vertical-right-content-title {
    font-size: 18px;
    line-height: 24px;
  }

  .vertical-blocks-section .vertical-right-content-description {
    margin-top: 8px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
  }

  .vertical-blocks-section .vertical-right-content-link-row {
    margin-top: 20px;
  }

  .vertical-blocks-section .vertical-right-content-link {
    font-size: 12px;
  }
}
    
@media (min-width: 768px) and (max-width: 1023px) {
  .vertical-blocks-section {
    padding: 40px 30px;
  }
  
  .vertical-blocks-section .vertical-blocks-left-content {
    width: 45%;
  }
  
  .vertical-blocks-section .vertical-left-image-block {
    height: 320px;
  }
  
  .vertical-blocks-section .vertical-blocks-right-content {
    width: 52%;
  }
  
  .vertical-blocks-section .vertical-right-image-block {
    width: 150px;
    height: 160px;
  }
  
  .vertical-blocks-section .vertical-right-content-block {
    width: calc(100% - 150px);
    padding-left: 20px;
  }
  
  .vertical-blocks-section .vertical-right-content-title {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (min-width: 1024px) and (max-width: 1399px) {
  .vertical-blocks-section {
    padding: 40px 30px;
  }
}
`

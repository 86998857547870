import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { GET_CONTENTS, getArticlesCustomQueryVariables } from 'Queries'
import { LazyLoadImage } from 'Blocks'
import { path } from 'Utils';

const LatestArticles = props => {
  let nItems = [];
  const variables = getArticlesCustomQueryVariables(
    '',
    9,
    'article',
    false,
    '',
    nItems,
    'yes'
  )
  const { loading, error, data } = useQuery(GET_CONTENTS, { variables });

  const LoadURL = (type, uuid, slug) => {
    return path(type, { uuid, slug });
  }

  if(!data) return null;

  return (
    <div className={props.className}>
      <div className="articles-section news-section">
        <div className="section-container">
          <div className="articles-section-title">The latest</div>
          <div className="articles-section-description">Keep up to date with our style guides, grooming reviews and lifestyle essentials</div>
          <div className="articles-content-section">
          {data.contents.contents.map((k) => (
            <div className="articles-content-block"  key={`latest-${k.uuid}`}>
              <div className="articles-content-image-block">
                <Link to={LoadURL(k.type, k.uuid, k.slug)} className="slider-card-link">
                  <LazyLoadImage
                    alt={k.title.short}
                    width={props.screenType === 'small' ? 154 : 365}
                    height={props.screenType === 'small' ? 142.08 : 338}
                    image={k.image.main}
                    className="global-image"
                  />
                </Link>
              </div>
              <div className="articles-content-title-block">The Many Wardrobes Of The Talented (And Stylish) Mr Ripley</div>
              <div className="articles-content-description-block">Discover iconic accessories and luxe separates with the brand's latest in-demand lineup.</div>
              <div className="articles-content-link-block">
                <Link to={LoadURL(k.type, k.uuid, k.slug)} className="primary-link articles-content-link">Read more</Link>
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LatestArticles
